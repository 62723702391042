import { default as indexKnt05PTJTYMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/admin/index.vue?macro=true";
import { default as cartao5vtquPxvieMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/cartao.vue?macro=true";
import { default as index2sM4fg4WfIMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/index.vue?macro=true";
import { default as assinatura_45realizadaJBcPslywxmMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/assinatura-realizada.vue?macro=true";
import { default as indexUL2QHNP3MoMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/bem-vindo/index.vue?macro=true";
import { default as indexo4l2FXMc2HMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/index.vue?macro=true";
import { default as _91transactionId_93ltCc6FArl8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/recibo/[transactionId].vue?macro=true";
import { default as _91shiftId_939rHeKRSrDTMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/relatorio/[shiftId].vue?macro=true";
import { default as extrato_45de_45debitoslSpguE7BnZMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-debitos.vue?macro=true";
import { default as extrato_45de_45pagamentosLzaKAasepyMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-pagamentos.vue?macro=true";
import { default as importarXL5xwkm22MMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/importar.vue?macro=true";
import { default as indexKnSvT5otfCMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/index.vue?macro=true";
import { default as _91uuid_93MD9UzOg3sEMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/orcamento/[uuid].vue?macro=true";
import { default as _91uuid_93FRsLPMNVmLMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico-recibo/[uuid].vue?macro=true";
import { default as _91uuid_93L6C3r07sw4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico/[uuid].vue?macro=true";
import { default as _91uuid_93vVHjcq2f26Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo-crediario/[uuid].vue?macro=true";
import { default as _91uuid_93z3nmvJIs48Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo/[uuid].vue?macro=true";
import { default as assinaturaiLFC6SDxFeMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/assinatura.vue?macro=true";
import { default as calculo_45de_45jurosXEht9ihrrVMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/calculo-de-juros.vue?macro=true";
import { default as catalogoUFAeiKpiwSMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/catalogo.vue?macro=true";
import { default as configuracao_45do_45usuariobTVwnveEaCMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-usuario.vue?macro=true";
import { default as configuracoes_45fiscaissJ6h8b13iVMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracoes-fiscais.vue?macro=true";
import { default as creditos_45ia6YF0JhadUDMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/creditos-ia.vue?macro=true";
import { default as empresaVeERRQkM2SMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/empresa.vue?macro=true";
import { default as formas_45de_45pagamentocCtMdBxObsMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/formas-de-pagamento.vue?macro=true";
import { default as index_32copyPiq201uAkPMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index copy.vue?macro=true";
import { default as index4Ic2tjttHWMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index.vue?macro=true";
import { default as integracao_45mercado_45pagoiultGucexFMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/integracao-mercado-pago.vue?macro=true";
import { default as modelos_45de_45mensagensaRdxH9T8P1Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/modelos-de-mensagens.vue?macro=true";
import { default as _91naturezaOperacaoId_93o6DWhSL2QdMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/editar/[naturezaOperacaoId].vue?macro=true";
import { default as indexChTBgCmy5tMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/index.vue?macro=true";
import { default as indexpD9LM5iXiKMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/novo/index.vue?macro=true";
import { default as ordem_45de_45servicoO0b2zWPZvVMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/ordem-de-servico.vue?macro=true";
import { default as recibo7y66bQy8ROMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/recibo.vue?macro=true";
import { default as regras_45fiscaisuvT5GikocLMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/regras-fiscais.vue?macro=true";
import { default as index8lVYDCVZB6Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/contas-a-pagar/index.vue?macro=true";
import { default as _91uid_931jHseWaWTEMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/convite/[uid].vue?macro=true";
import { default as criar_45contaizoBYgD5ZJMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/criar-conta.vue?macro=true";
import { default as indexwVRlZ5hJr4Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/estatisticas/index.vue?macro=true";
import { default as forgot_45passwordXrawhBTBeQMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/forgot-password.vue?macro=true";
import { default as formulario_45enviadon3ad0fAI2IMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/formulario-enviado.vue?macro=true";
import { default as indexWMg4J7lRATMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/index.vue?macro=true";
import { default as loginZ0m1Xi7hFBMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/login.vue?macro=true";
import { default as minhas_45empresasPyoTlKCrE1Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/minhas-empresas.vue?macro=true";
import { default as _91nfeId_93hnYRbMr0jwMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/editar/[modelo]/[nfeId].vue?macro=true";
import { default as indexdb0z2T6eJgMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/index.vue?macro=true";
import { default as indexAhcQ6FRcG8Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/inutilizacao/index.vue?macro=true";
import { default as _91modelo_93eA66PE5vnLMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/novo/[modelo].vue?macro=true";
import { default as _91nfeId_93FiMyQbhbU9Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/visualizar/[modelo]/[nfeId].vue?macro=true";
import { default as indexQRPSg3yXi1Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/notificacoes/index.vue?macro=true";
import { default as nova_45empresaqqAcTF2RviMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/nova-empresa.vue?macro=true";
import { default as _91serviceOrderId_93ycCedNc1DTMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/editar/[serviceOrderId].vue?macro=true";
import { default as importar_45servico0ffhnbyti6Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/importar-servico.vue?macro=true";
import { default as _91id_93TdqLdG68snMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/imprimir/[id].vue?macro=true";
import { default as indexfoadIFXVZ1Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/index.vue?macro=true";
import { default as novoU1fNLUVuUBMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/novo.vue?macro=true";
import { default as _91id_93agg7TUnjTVMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/recibo/[id].vue?macro=true";
import { default as _91token_93Op0efYJg1zMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/password-reset/[token].vue?macro=true";
import { default as _91purchaseId_93bLctKbkG4rMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/editar/[purchaseId].vue?macro=true";
import { default as novo3w5RmmLwOdMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/novo.vue?macro=true";
import { default as imprimir96aab4vuqxMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/imprimir.vue?macro=true";
import { default as indexN8MbcmHB9VMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/index.vue?macro=true";
import { default as V11I7q9kzt7lMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/V1.vue?macro=true";
import { default as importarFrARMYkZ6QMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/importar.vue?macro=true";
import { default as indexkhO0PJFh6ZMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/index.vue?macro=true";
import { default as programa_45de_45indicacaoONAjszckqxMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/programa-de-indicacao.vue?macro=true";
import { default as index8dw9Wq3tK9Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/usuarios/index.vue?macro=true";
import { default as carrinhoekf1LMgoHAMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/carrinho.vue?macro=true";
import { default as _91saleId_93cQEnhrP46RMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/editar/[saleId].vue?macro=true";
import { default as indexjmOADeGp8IMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/index.vue?macro=true";
import { default as metas_45de_45vendasZZpGptUZQ3Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/metas-de-vendas.vue?macro=true";
import { default as novofQgT1vQ3W6Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/novo.vue?macro=true";
import { default as _91budgetId_93zucCgqzvxiMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/orcamento/[budgetId].vue?macro=true";
import { default as produtosvF7Eer9M3SMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/produtos.vue?macro=true";
import { default as _91billReceivableId_93oFJa9F2ag7Meta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/[billReceivableId].vue?macro=true";
import { default as indexl41rOQ7ABmMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/index.vue?macro=true";
import { default as _91saleId_93QJcpPmT8NoMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo-teste/[saleId].vue?macro=true";
import { default as _91saleId_93Lma1cBk49TMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo/[saleId].vue?macro=true";
import { default as indexdSYSE88XfkMeta } from "/home/runner/work/apex-pos/apex-pos/frontend/pages/vendedores/index.vue?macro=true";
export default [
  {
    name: indexKnt05PTJTYMeta?.name ?? "admin",
    path: indexKnt05PTJTYMeta?.path ?? "/admin",
    meta: indexKnt05PTJTYMeta || {},
    alias: indexKnt05PTJTYMeta?.alias || [],
    redirect: indexKnt05PTJTYMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: cartao5vtquPxvieMeta?.name ?? "assinar-cartao",
    path: cartao5vtquPxvieMeta?.path ?? "/assinar/cartao",
    meta: cartao5vtquPxvieMeta || {},
    alias: cartao5vtquPxvieMeta?.alias || [],
    redirect: cartao5vtquPxvieMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/cartao.vue").then(m => m.default || m)
  },
  {
    name: index2sM4fg4WfIMeta?.name ?? "assinar",
    path: index2sM4fg4WfIMeta?.path ?? "/assinar",
    meta: index2sM4fg4WfIMeta || {},
    alias: index2sM4fg4WfIMeta?.alias || [],
    redirect: index2sM4fg4WfIMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinar/index.vue").then(m => m.default || m)
  },
  {
    name: assinatura_45realizadaJBcPslywxmMeta?.name ?? "assinatura-realizada",
    path: assinatura_45realizadaJBcPslywxmMeta?.path ?? "/assinatura-realizada",
    meta: assinatura_45realizadaJBcPslywxmMeta || {},
    alias: assinatura_45realizadaJBcPslywxmMeta?.alias || [],
    redirect: assinatura_45realizadaJBcPslywxmMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/assinatura-realizada.vue").then(m => m.default || m)
  },
  {
    name: indexUL2QHNP3MoMeta?.name ?? "bem-vindo",
    path: indexUL2QHNP3MoMeta?.path ?? "/bem-vindo",
    meta: indexUL2QHNP3MoMeta || {},
    alias: indexUL2QHNP3MoMeta?.alias || [],
    redirect: indexUL2QHNP3MoMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/bem-vindo/index.vue").then(m => m.default || m)
  },
  {
    name: indexo4l2FXMc2HMeta?.name ?? "caixa",
    path: indexo4l2FXMc2HMeta?.path ?? "/caixa",
    meta: indexo4l2FXMc2HMeta || {},
    alias: indexo4l2FXMc2HMeta?.alias || [],
    redirect: indexo4l2FXMc2HMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/index.vue").then(m => m.default || m)
  },
  {
    name: _91transactionId_93ltCc6FArl8Meta?.name ?? "caixa-recibo-transactionId",
    path: _91transactionId_93ltCc6FArl8Meta?.path ?? "/caixa/recibo/:transactionId()",
    meta: _91transactionId_93ltCc6FArl8Meta || {},
    alias: _91transactionId_93ltCc6FArl8Meta?.alias || [],
    redirect: _91transactionId_93ltCc6FArl8Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/recibo/[transactionId].vue").then(m => m.default || m)
  },
  {
    name: _91shiftId_939rHeKRSrDTMeta?.name ?? "caixa-relatorio-shiftId",
    path: _91shiftId_939rHeKRSrDTMeta?.path ?? "/caixa/relatorio/:shiftId()",
    meta: _91shiftId_939rHeKRSrDTMeta || {},
    alias: _91shiftId_939rHeKRSrDTMeta?.alias || [],
    redirect: _91shiftId_939rHeKRSrDTMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/caixa/relatorio/[shiftId].vue").then(m => m.default || m)
  },
  {
    name: extrato_45de_45debitoslSpguE7BnZMeta?.name ?? "clientes-contas-a-receber-extrato-de-debitos",
    path: extrato_45de_45debitoslSpguE7BnZMeta?.path ?? "/clientes/contas-a-receber/extrato-de-debitos",
    meta: extrato_45de_45debitoslSpguE7BnZMeta || {},
    alias: extrato_45de_45debitoslSpguE7BnZMeta?.alias || [],
    redirect: extrato_45de_45debitoslSpguE7BnZMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-debitos.vue").then(m => m.default || m)
  },
  {
    name: extrato_45de_45pagamentosLzaKAasepyMeta?.name ?? "clientes-contas-a-receber-extrato-de-pagamentos",
    path: extrato_45de_45pagamentosLzaKAasepyMeta?.path ?? "/clientes/contas-a-receber/extrato-de-pagamentos",
    meta: extrato_45de_45pagamentosLzaKAasepyMeta || {},
    alias: extrato_45de_45pagamentosLzaKAasepyMeta?.alias || [],
    redirect: extrato_45de_45pagamentosLzaKAasepyMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/contas-a-receber/extrato-de-pagamentos.vue").then(m => m.default || m)
  },
  {
    name: importarXL5xwkm22MMeta?.name ?? "clientes-importar",
    path: importarXL5xwkm22MMeta?.path ?? "/clientes/importar",
    meta: importarXL5xwkm22MMeta || {},
    alias: importarXL5xwkm22MMeta?.alias || [],
    redirect: importarXL5xwkm22MMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/importar.vue").then(m => m.default || m)
  },
  {
    name: indexKnSvT5otfCMeta?.name ?? "clientes",
    path: indexKnSvT5otfCMeta?.path ?? "/clientes",
    meta: indexKnSvT5otfCMeta || {},
    alias: indexKnSvT5otfCMeta?.alias || [],
    redirect: indexKnSvT5otfCMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/clientes/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93MD9UzOg3sEMeta?.name ?? "comprovantes-orcamento-uuid",
    path: _91uuid_93MD9UzOg3sEMeta?.path ?? "/comprovantes/orcamento/:uuid()",
    meta: _91uuid_93MD9UzOg3sEMeta || {},
    alias: _91uuid_93MD9UzOg3sEMeta?.alias || [],
    redirect: _91uuid_93MD9UzOg3sEMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/orcamento/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93FRsLPMNVmLMeta?.name ?? "comprovantes-ordem-servico-recibo-uuid",
    path: _91uuid_93FRsLPMNVmLMeta?.path ?? "/comprovantes/ordem-servico-recibo/:uuid()",
    meta: _91uuid_93FRsLPMNVmLMeta || {},
    alias: _91uuid_93FRsLPMNVmLMeta?.alias || [],
    redirect: _91uuid_93FRsLPMNVmLMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico-recibo/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93L6C3r07sw4Meta?.name ?? "comprovantes-ordem-servico-uuid",
    path: _91uuid_93L6C3r07sw4Meta?.path ?? "/comprovantes/ordem-servico/:uuid()",
    meta: _91uuid_93L6C3r07sw4Meta || {},
    alias: _91uuid_93L6C3r07sw4Meta?.alias || [],
    redirect: _91uuid_93L6C3r07sw4Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/ordem-servico/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93vVHjcq2f26Meta?.name ?? "comprovantes-recibo-crediario-uuid",
    path: _91uuid_93vVHjcq2f26Meta?.path ?? "/comprovantes/recibo-crediario/:uuid()",
    meta: _91uuid_93vVHjcq2f26Meta || {},
    alias: _91uuid_93vVHjcq2f26Meta?.alias || [],
    redirect: _91uuid_93vVHjcq2f26Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo-crediario/[uuid].vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93z3nmvJIs48Meta?.name ?? "comprovantes-recibo-uuid",
    path: _91uuid_93z3nmvJIs48Meta?.path ?? "/comprovantes/recibo/:uuid()",
    meta: _91uuid_93z3nmvJIs48Meta || {},
    alias: _91uuid_93z3nmvJIs48Meta?.alias || [],
    redirect: _91uuid_93z3nmvJIs48Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/comprovantes/recibo/[uuid].vue").then(m => m.default || m)
  },
  {
    name: assinaturaiLFC6SDxFeMeta?.name ?? "configuracoes-assinatura",
    path: assinaturaiLFC6SDxFeMeta?.path ?? "/configuracoes/assinatura",
    meta: assinaturaiLFC6SDxFeMeta || {},
    alias: assinaturaiLFC6SDxFeMeta?.alias || [],
    redirect: assinaturaiLFC6SDxFeMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/assinatura.vue").then(m => m.default || m)
  },
  {
    name: calculo_45de_45jurosXEht9ihrrVMeta?.name ?? "configuracoes-calculo-de-juros",
    path: calculo_45de_45jurosXEht9ihrrVMeta?.path ?? "/configuracoes/calculo-de-juros",
    meta: calculo_45de_45jurosXEht9ihrrVMeta || {},
    alias: calculo_45de_45jurosXEht9ihrrVMeta?.alias || [],
    redirect: calculo_45de_45jurosXEht9ihrrVMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/calculo-de-juros.vue").then(m => m.default || m)
  },
  {
    name: catalogoUFAeiKpiwSMeta?.name ?? "configuracoes-catalogo",
    path: catalogoUFAeiKpiwSMeta?.path ?? "/configuracoes/catalogo",
    meta: catalogoUFAeiKpiwSMeta || {},
    alias: catalogoUFAeiKpiwSMeta?.alias || [],
    redirect: catalogoUFAeiKpiwSMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/catalogo.vue").then(m => m.default || m)
  },
  {
    name: configuracao_45do_45usuariobTVwnveEaCMeta?.name ?? "configuracoes-configuracao-do-usuario",
    path: configuracao_45do_45usuariobTVwnveEaCMeta?.path ?? "/configuracoes/configuracao-do-usuario",
    meta: configuracao_45do_45usuariobTVwnveEaCMeta || {},
    alias: configuracao_45do_45usuariobTVwnveEaCMeta?.alias || [],
    redirect: configuracao_45do_45usuariobTVwnveEaCMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracao-do-usuario.vue").then(m => m.default || m)
  },
  {
    name: configuracoes_45fiscaissJ6h8b13iVMeta?.name ?? "configuracoes-configuracoes-fiscais",
    path: configuracoes_45fiscaissJ6h8b13iVMeta?.path ?? "/configuracoes/configuracoes-fiscais",
    meta: configuracoes_45fiscaissJ6h8b13iVMeta || {},
    alias: configuracoes_45fiscaissJ6h8b13iVMeta?.alias || [],
    redirect: configuracoes_45fiscaissJ6h8b13iVMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/configuracoes-fiscais.vue").then(m => m.default || m)
  },
  {
    name: creditos_45ia6YF0JhadUDMeta?.name ?? "configuracoes-creditos-ia",
    path: creditos_45ia6YF0JhadUDMeta?.path ?? "/configuracoes/creditos-ia",
    meta: creditos_45ia6YF0JhadUDMeta || {},
    alias: creditos_45ia6YF0JhadUDMeta?.alias || [],
    redirect: creditos_45ia6YF0JhadUDMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/creditos-ia.vue").then(m => m.default || m)
  },
  {
    name: empresaVeERRQkM2SMeta?.name ?? "configuracoes-empresa",
    path: empresaVeERRQkM2SMeta?.path ?? "/configuracoes/empresa",
    meta: empresaVeERRQkM2SMeta || {},
    alias: empresaVeERRQkM2SMeta?.alias || [],
    redirect: empresaVeERRQkM2SMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/empresa.vue").then(m => m.default || m)
  },
  {
    name: formas_45de_45pagamentocCtMdBxObsMeta?.name ?? "configuracoes-formas-de-pagamento",
    path: formas_45de_45pagamentocCtMdBxObsMeta?.path ?? "/configuracoes/formas-de-pagamento",
    meta: formas_45de_45pagamentocCtMdBxObsMeta || {},
    alias: formas_45de_45pagamentocCtMdBxObsMeta?.alias || [],
    redirect: formas_45de_45pagamentocCtMdBxObsMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/formas-de-pagamento.vue").then(m => m.default || m)
  },
  {
    name: index_32copyPiq201uAkPMeta?.name ?? "configuracoes-index copy",
    path: index_32copyPiq201uAkPMeta?.path ?? "/configuracoes/index%20copy",
    meta: index_32copyPiq201uAkPMeta || {},
    alias: index_32copyPiq201uAkPMeta?.alias || [],
    redirect: index_32copyPiq201uAkPMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index copy.vue").then(m => m.default || m)
  },
  {
    name: index4Ic2tjttHWMeta?.name ?? "configuracoes",
    path: index4Ic2tjttHWMeta?.path ?? "/configuracoes",
    meta: index4Ic2tjttHWMeta || {},
    alias: index4Ic2tjttHWMeta?.alias || [],
    redirect: index4Ic2tjttHWMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/index.vue").then(m => m.default || m)
  },
  {
    name: integracao_45mercado_45pagoiultGucexFMeta?.name ?? "configuracoes-integracao-mercado-pago",
    path: integracao_45mercado_45pagoiultGucexFMeta?.path ?? "/configuracoes/integracao-mercado-pago",
    meta: integracao_45mercado_45pagoiultGucexFMeta || {},
    alias: integracao_45mercado_45pagoiultGucexFMeta?.alias || [],
    redirect: integracao_45mercado_45pagoiultGucexFMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/integracao-mercado-pago.vue").then(m => m.default || m)
  },
  {
    name: modelos_45de_45mensagensaRdxH9T8P1Meta?.name ?? "configuracoes-modelos-de-mensagens",
    path: modelos_45de_45mensagensaRdxH9T8P1Meta?.path ?? "/configuracoes/modelos-de-mensagens",
    meta: modelos_45de_45mensagensaRdxH9T8P1Meta || {},
    alias: modelos_45de_45mensagensaRdxH9T8P1Meta?.alias || [],
    redirect: modelos_45de_45mensagensaRdxH9T8P1Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/modelos-de-mensagens.vue").then(m => m.default || m)
  },
  {
    name: _91naturezaOperacaoId_93o6DWhSL2QdMeta?.name ?? "configuracoes-natureza-operacao-editar-naturezaOperacaoId",
    path: _91naturezaOperacaoId_93o6DWhSL2QdMeta?.path ?? "/configuracoes/natureza-operacao/editar/:naturezaOperacaoId()",
    meta: _91naturezaOperacaoId_93o6DWhSL2QdMeta || {},
    alias: _91naturezaOperacaoId_93o6DWhSL2QdMeta?.alias || [],
    redirect: _91naturezaOperacaoId_93o6DWhSL2QdMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/editar/[naturezaOperacaoId].vue").then(m => m.default || m)
  },
  {
    name: indexChTBgCmy5tMeta?.name ?? "configuracoes-natureza-operacao",
    path: indexChTBgCmy5tMeta?.path ?? "/configuracoes/natureza-operacao",
    meta: indexChTBgCmy5tMeta || {},
    alias: indexChTBgCmy5tMeta?.alias || [],
    redirect: indexChTBgCmy5tMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/index.vue").then(m => m.default || m)
  },
  {
    name: indexpD9LM5iXiKMeta?.name ?? "configuracoes-natureza-operacao-novo",
    path: indexpD9LM5iXiKMeta?.path ?? "/configuracoes/natureza-operacao/novo",
    meta: indexpD9LM5iXiKMeta || {},
    alias: indexpD9LM5iXiKMeta?.alias || [],
    redirect: indexpD9LM5iXiKMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/natureza-operacao/novo/index.vue").then(m => m.default || m)
  },
  {
    name: ordem_45de_45servicoO0b2zWPZvVMeta?.name ?? "configuracoes-ordem-de-servico",
    path: ordem_45de_45servicoO0b2zWPZvVMeta?.path ?? "/configuracoes/ordem-de-servico",
    meta: ordem_45de_45servicoO0b2zWPZvVMeta || {},
    alias: ordem_45de_45servicoO0b2zWPZvVMeta?.alias || [],
    redirect: ordem_45de_45servicoO0b2zWPZvVMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/ordem-de-servico.vue").then(m => m.default || m)
  },
  {
    name: recibo7y66bQy8ROMeta?.name ?? "configuracoes-recibo",
    path: recibo7y66bQy8ROMeta?.path ?? "/configuracoes/recibo",
    meta: recibo7y66bQy8ROMeta || {},
    alias: recibo7y66bQy8ROMeta?.alias || [],
    redirect: recibo7y66bQy8ROMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/recibo.vue").then(m => m.default || m)
  },
  {
    name: regras_45fiscaisuvT5GikocLMeta?.name ?? "configuracoes-regras-fiscais",
    path: regras_45fiscaisuvT5GikocLMeta?.path ?? "/configuracoes/regras-fiscais",
    meta: regras_45fiscaisuvT5GikocLMeta || {},
    alias: regras_45fiscaisuvT5GikocLMeta?.alias || [],
    redirect: regras_45fiscaisuvT5GikocLMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/configuracoes/regras-fiscais.vue").then(m => m.default || m)
  },
  {
    name: index8lVYDCVZB6Meta?.name ?? "contas-a-pagar",
    path: index8lVYDCVZB6Meta?.path ?? "/contas-a-pagar",
    meta: index8lVYDCVZB6Meta || {},
    alias: index8lVYDCVZB6Meta?.alias || [],
    redirect: index8lVYDCVZB6Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/contas-a-pagar/index.vue").then(m => m.default || m)
  },
  {
    name: _91uid_931jHseWaWTEMeta?.name ?? "convite-uid",
    path: _91uid_931jHseWaWTEMeta?.path ?? "/convite/:uid()",
    meta: _91uid_931jHseWaWTEMeta || {},
    alias: _91uid_931jHseWaWTEMeta?.alias || [],
    redirect: _91uid_931jHseWaWTEMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/convite/[uid].vue").then(m => m.default || m)
  },
  {
    name: criar_45contaizoBYgD5ZJMeta?.name ?? "criar-conta",
    path: criar_45contaizoBYgD5ZJMeta?.path ?? "/criar-conta",
    meta: criar_45contaizoBYgD5ZJMeta || {},
    alias: criar_45contaizoBYgD5ZJMeta?.alias || [],
    redirect: criar_45contaizoBYgD5ZJMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/criar-conta.vue").then(m => m.default || m)
  },
  {
    name: indexwVRlZ5hJr4Meta?.name ?? "estatisticas",
    path: indexwVRlZ5hJr4Meta?.path ?? "/estatisticas",
    meta: indexwVRlZ5hJr4Meta || {},
    alias: indexwVRlZ5hJr4Meta?.alias || [],
    redirect: indexwVRlZ5hJr4Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/estatisticas/index.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordXrawhBTBeQMeta?.name ?? "forgot-password",
    path: forgot_45passwordXrawhBTBeQMeta?.path ?? "/forgot-password",
    meta: forgot_45passwordXrawhBTBeQMeta || {},
    alias: forgot_45passwordXrawhBTBeQMeta?.alias || [],
    redirect: forgot_45passwordXrawhBTBeQMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: formulario_45enviadon3ad0fAI2IMeta?.name ?? "formulario-enviado",
    path: formulario_45enviadon3ad0fAI2IMeta?.path ?? "/formulario-enviado",
    meta: formulario_45enviadon3ad0fAI2IMeta || {},
    alias: formulario_45enviadon3ad0fAI2IMeta?.alias || [],
    redirect: formulario_45enviadon3ad0fAI2IMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/formulario-enviado.vue").then(m => m.default || m)
  },
  {
    name: indexWMg4J7lRATMeta?.name ?? "index",
    path: indexWMg4J7lRATMeta?.path ?? "/",
    meta: indexWMg4J7lRATMeta || {},
    alias: indexWMg4J7lRATMeta?.alias || [],
    redirect: indexWMg4J7lRATMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginZ0m1Xi7hFBMeta?.name ?? "login",
    path: loginZ0m1Xi7hFBMeta?.path ?? "/login",
    meta: loginZ0m1Xi7hFBMeta || {},
    alias: loginZ0m1Xi7hFBMeta?.alias || [],
    redirect: loginZ0m1Xi7hFBMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: minhas_45empresasPyoTlKCrE1Meta?.name ?? "minhas-empresas",
    path: minhas_45empresasPyoTlKCrE1Meta?.path ?? "/minhas-empresas",
    meta: minhas_45empresasPyoTlKCrE1Meta || {},
    alias: minhas_45empresasPyoTlKCrE1Meta?.alias || [],
    redirect: minhas_45empresasPyoTlKCrE1Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/minhas-empresas.vue").then(m => m.default || m)
  },
  {
    name: _91nfeId_93hnYRbMr0jwMeta?.name ?? "nfe-editar-modelo-nfeId",
    path: _91nfeId_93hnYRbMr0jwMeta?.path ?? "/nfe/editar/:modelo()/:nfeId()",
    meta: _91nfeId_93hnYRbMr0jwMeta || {},
    alias: _91nfeId_93hnYRbMr0jwMeta?.alias || [],
    redirect: _91nfeId_93hnYRbMr0jwMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/editar/[modelo]/[nfeId].vue").then(m => m.default || m)
  },
  {
    name: indexdb0z2T6eJgMeta?.name ?? "nfe",
    path: indexdb0z2T6eJgMeta?.path ?? "/nfe",
    meta: indexdb0z2T6eJgMeta || {},
    alias: indexdb0z2T6eJgMeta?.alias || [],
    redirect: indexdb0z2T6eJgMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/index.vue").then(m => m.default || m)
  },
  {
    name: indexAhcQ6FRcG8Meta?.name ?? "nfe-inutilizacao",
    path: indexAhcQ6FRcG8Meta?.path ?? "/nfe/inutilizacao",
    meta: indexAhcQ6FRcG8Meta || {},
    alias: indexAhcQ6FRcG8Meta?.alias || [],
    redirect: indexAhcQ6FRcG8Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/inutilizacao/index.vue").then(m => m.default || m)
  },
  {
    name: _91modelo_93eA66PE5vnLMeta?.name ?? "nfe-novo-modelo",
    path: _91modelo_93eA66PE5vnLMeta?.path ?? "/nfe/novo/:modelo()",
    meta: _91modelo_93eA66PE5vnLMeta || {},
    alias: _91modelo_93eA66PE5vnLMeta?.alias || [],
    redirect: _91modelo_93eA66PE5vnLMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/novo/[modelo].vue").then(m => m.default || m)
  },
  {
    name: _91nfeId_93FiMyQbhbU9Meta?.name ?? "nfe-visualizar-modelo-nfeId",
    path: _91nfeId_93FiMyQbhbU9Meta?.path ?? "/nfe/visualizar/:modelo()/:nfeId()",
    meta: _91nfeId_93FiMyQbhbU9Meta || {},
    alias: _91nfeId_93FiMyQbhbU9Meta?.alias || [],
    redirect: _91nfeId_93FiMyQbhbU9Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nfe/visualizar/[modelo]/[nfeId].vue").then(m => m.default || m)
  },
  {
    name: indexQRPSg3yXi1Meta?.name ?? "notificacoes",
    path: indexQRPSg3yXi1Meta?.path ?? "/notificacoes",
    meta: indexQRPSg3yXi1Meta || {},
    alias: indexQRPSg3yXi1Meta?.alias || [],
    redirect: indexQRPSg3yXi1Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/notificacoes/index.vue").then(m => m.default || m)
  },
  {
    name: nova_45empresaqqAcTF2RviMeta?.name ?? "nova-empresa",
    path: nova_45empresaqqAcTF2RviMeta?.path ?? "/nova-empresa",
    meta: nova_45empresaqqAcTF2RviMeta || {},
    alias: nova_45empresaqqAcTF2RviMeta?.alias || [],
    redirect: nova_45empresaqqAcTF2RviMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/nova-empresa.vue").then(m => m.default || m)
  },
  {
    name: _91serviceOrderId_93ycCedNc1DTMeta?.name ?? "ordem-servico-editar-serviceOrderId",
    path: _91serviceOrderId_93ycCedNc1DTMeta?.path ?? "/ordem-servico/editar/:serviceOrderId()",
    meta: _91serviceOrderId_93ycCedNc1DTMeta || {},
    alias: _91serviceOrderId_93ycCedNc1DTMeta?.alias || [],
    redirect: _91serviceOrderId_93ycCedNc1DTMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/editar/[serviceOrderId].vue").then(m => m.default || m)
  },
  {
    name: importar_45servico0ffhnbyti6Meta?.name ?? "ordem-servico-importar-servico",
    path: importar_45servico0ffhnbyti6Meta?.path ?? "/ordem-servico/importar-servico",
    meta: importar_45servico0ffhnbyti6Meta || {},
    alias: importar_45servico0ffhnbyti6Meta?.alias || [],
    redirect: importar_45servico0ffhnbyti6Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/importar-servico.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TdqLdG68snMeta?.name ?? "ordem-servico-imprimir-id",
    path: _91id_93TdqLdG68snMeta?.path ?? "/ordem-servico/imprimir/:id()",
    meta: _91id_93TdqLdG68snMeta || {},
    alias: _91id_93TdqLdG68snMeta?.alias || [],
    redirect: _91id_93TdqLdG68snMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/imprimir/[id].vue").then(m => m.default || m)
  },
  {
    name: indexfoadIFXVZ1Meta?.name ?? "ordem-servico",
    path: indexfoadIFXVZ1Meta?.path ?? "/ordem-servico",
    meta: indexfoadIFXVZ1Meta || {},
    alias: indexfoadIFXVZ1Meta?.alias || [],
    redirect: indexfoadIFXVZ1Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/index.vue").then(m => m.default || m)
  },
  {
    name: novoU1fNLUVuUBMeta?.name ?? "ordem-servico-novo",
    path: novoU1fNLUVuUBMeta?.path ?? "/ordem-servico/novo",
    meta: novoU1fNLUVuUBMeta || {},
    alias: novoU1fNLUVuUBMeta?.alias || [],
    redirect: novoU1fNLUVuUBMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/novo.vue").then(m => m.default || m)
  },
  {
    name: _91id_93agg7TUnjTVMeta?.name ?? "ordem-servico-recibo-id",
    path: _91id_93agg7TUnjTVMeta?.path ?? "/ordem-servico/recibo/:id()",
    meta: _91id_93agg7TUnjTVMeta || {},
    alias: _91id_93agg7TUnjTVMeta?.alias || [],
    redirect: _91id_93agg7TUnjTVMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/ordem-servico/recibo/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93Op0efYJg1zMeta?.name ?? "password-reset-token",
    path: _91token_93Op0efYJg1zMeta?.path ?? "/password-reset/:token()",
    meta: _91token_93Op0efYJg1zMeta || {},
    alias: _91token_93Op0efYJg1zMeta?.alias || [],
    redirect: _91token_93Op0efYJg1zMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/password-reset/[token].vue").then(m => m.default || m)
  },
  {
    name: _91purchaseId_93bLctKbkG4rMeta?.name ?? "produtos-compras-editar-purchaseId",
    path: _91purchaseId_93bLctKbkG4rMeta?.path ?? "/produtos/compras/editar/:purchaseId()",
    meta: _91purchaseId_93bLctKbkG4rMeta || {},
    alias: _91purchaseId_93bLctKbkG4rMeta?.alias || [],
    redirect: _91purchaseId_93bLctKbkG4rMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/editar/[purchaseId].vue").then(m => m.default || m)
  },
  {
    name: novo3w5RmmLwOdMeta?.name ?? "produtos-compras-novo",
    path: novo3w5RmmLwOdMeta?.path ?? "/produtos/compras/novo",
    meta: novo3w5RmmLwOdMeta || {},
    alias: novo3w5RmmLwOdMeta?.alias || [],
    redirect: novo3w5RmmLwOdMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/compras/novo.vue").then(m => m.default || m)
  },
  {
    name: imprimir96aab4vuqxMeta?.name ?? "produtos-etiquetas-imprimir",
    path: imprimir96aab4vuqxMeta?.path ?? "/produtos/etiquetas/imprimir",
    meta: imprimir96aab4vuqxMeta || {},
    alias: imprimir96aab4vuqxMeta?.alias || [],
    redirect: imprimir96aab4vuqxMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/imprimir.vue").then(m => m.default || m)
  },
  {
    name: indexN8MbcmHB9VMeta?.name ?? "produtos-etiquetas",
    path: indexN8MbcmHB9VMeta?.path ?? "/produtos/etiquetas",
    meta: indexN8MbcmHB9VMeta || {},
    alias: indexN8MbcmHB9VMeta?.alias || [],
    redirect: indexN8MbcmHB9VMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/index.vue").then(m => m.default || m)
  },
  {
    name: V11I7q9kzt7lMeta?.name ?? "produtos-etiquetas-V1",
    path: V11I7q9kzt7lMeta?.path ?? "/produtos/etiquetas/V1",
    meta: V11I7q9kzt7lMeta || {},
    alias: V11I7q9kzt7lMeta?.alias || [],
    redirect: V11I7q9kzt7lMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/etiquetas/V1.vue").then(m => m.default || m)
  },
  {
    name: importarFrARMYkZ6QMeta?.name ?? "produtos-importar",
    path: importarFrARMYkZ6QMeta?.path ?? "/produtos/importar",
    meta: importarFrARMYkZ6QMeta || {},
    alias: importarFrARMYkZ6QMeta?.alias || [],
    redirect: importarFrARMYkZ6QMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/importar.vue").then(m => m.default || m)
  },
  {
    name: indexkhO0PJFh6ZMeta?.name ?? "produtos",
    path: indexkhO0PJFh6ZMeta?.path ?? "/produtos",
    meta: indexkhO0PJFh6ZMeta || {},
    alias: indexkhO0PJFh6ZMeta?.alias || [],
    redirect: indexkhO0PJFh6ZMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/produtos/index.vue").then(m => m.default || m)
  },
  {
    name: programa_45de_45indicacaoONAjszckqxMeta?.name ?? "programa-de-indicacao",
    path: programa_45de_45indicacaoONAjszckqxMeta?.path ?? "/programa-de-indicacao",
    meta: programa_45de_45indicacaoONAjszckqxMeta || {},
    alias: programa_45de_45indicacaoONAjszckqxMeta?.alias || [],
    redirect: programa_45de_45indicacaoONAjszckqxMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/programa-de-indicacao.vue").then(m => m.default || m)
  },
  {
    name: index8dw9Wq3tK9Meta?.name ?? "usuarios",
    path: index8dw9Wq3tK9Meta?.path ?? "/usuarios",
    meta: index8dw9Wq3tK9Meta || {},
    alias: index8dw9Wq3tK9Meta?.alias || [],
    redirect: index8dw9Wq3tK9Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/usuarios/index.vue").then(m => m.default || m)
  },
  {
    name: carrinhoekf1LMgoHAMeta?.name ?? "vendas-carrinho",
    path: carrinhoekf1LMgoHAMeta?.path ?? "/vendas/carrinho",
    meta: carrinhoekf1LMgoHAMeta || {},
    alias: carrinhoekf1LMgoHAMeta?.alias || [],
    redirect: carrinhoekf1LMgoHAMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/carrinho.vue").then(m => m.default || m)
  },
  {
    name: _91saleId_93cQEnhrP46RMeta?.name ?? "vendas-editar-saleId",
    path: _91saleId_93cQEnhrP46RMeta?.path ?? "/vendas/editar/:saleId()",
    meta: _91saleId_93cQEnhrP46RMeta || {},
    alias: _91saleId_93cQEnhrP46RMeta?.alias || [],
    redirect: _91saleId_93cQEnhrP46RMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/editar/[saleId].vue").then(m => m.default || m)
  },
  {
    name: indexjmOADeGp8IMeta?.name ?? "vendas",
    path: indexjmOADeGp8IMeta?.path ?? "/vendas",
    meta: indexjmOADeGp8IMeta || {},
    alias: indexjmOADeGp8IMeta?.alias || [],
    redirect: indexjmOADeGp8IMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/index.vue").then(m => m.default || m)
  },
  {
    name: metas_45de_45vendasZZpGptUZQ3Meta?.name ?? "vendas-metas-de-vendas",
    path: metas_45de_45vendasZZpGptUZQ3Meta?.path ?? "/vendas/metas-de-vendas",
    meta: metas_45de_45vendasZZpGptUZQ3Meta || {},
    alias: metas_45de_45vendasZZpGptUZQ3Meta?.alias || [],
    redirect: metas_45de_45vendasZZpGptUZQ3Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/metas-de-vendas.vue").then(m => m.default || m)
  },
  {
    name: novofQgT1vQ3W6Meta?.name ?? "vendas-novo",
    path: novofQgT1vQ3W6Meta?.path ?? "/vendas/novo",
    meta: novofQgT1vQ3W6Meta || {},
    alias: novofQgT1vQ3W6Meta?.alias || [],
    redirect: novofQgT1vQ3W6Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/novo.vue").then(m => m.default || m)
  },
  {
    name: _91budgetId_93zucCgqzvxiMeta?.name ?? "vendas-orcamento-budgetId",
    path: _91budgetId_93zucCgqzvxiMeta?.path ?? "/vendas/orcamento/:budgetId()",
    meta: _91budgetId_93zucCgqzvxiMeta || {},
    alias: _91budgetId_93zucCgqzvxiMeta?.alias || [],
    redirect: _91budgetId_93zucCgqzvxiMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/orcamento/[budgetId].vue").then(m => m.default || m)
  },
  {
    name: produtosvF7Eer9M3SMeta?.name ?? "vendas-produtos",
    path: produtosvF7Eer9M3SMeta?.path ?? "/vendas/produtos",
    meta: produtosvF7Eer9M3SMeta || {},
    alias: produtosvF7Eer9M3SMeta?.alias || [],
    redirect: produtosvF7Eer9M3SMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/produtos.vue").then(m => m.default || m)
  },
  {
    name: _91billReceivableId_93oFJa9F2ag7Meta?.name ?? "vendas-promissoria-billReceivableId",
    path: _91billReceivableId_93oFJa9F2ag7Meta?.path ?? "/vendas/promissoria/:billReceivableId()",
    meta: _91billReceivableId_93oFJa9F2ag7Meta || {},
    alias: _91billReceivableId_93oFJa9F2ag7Meta?.alias || [],
    redirect: _91billReceivableId_93oFJa9F2ag7Meta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/[billReceivableId].vue").then(m => m.default || m)
  },
  {
    name: indexl41rOQ7ABmMeta?.name ?? "vendas-promissoria",
    path: indexl41rOQ7ABmMeta?.path ?? "/vendas/promissoria",
    meta: indexl41rOQ7ABmMeta || {},
    alias: indexl41rOQ7ABmMeta?.alias || [],
    redirect: indexl41rOQ7ABmMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/promissoria/index.vue").then(m => m.default || m)
  },
  {
    name: _91saleId_93QJcpPmT8NoMeta?.name ?? "vendas-recibo-teste-saleId",
    path: _91saleId_93QJcpPmT8NoMeta?.path ?? "/vendas/recibo-teste/:saleId()",
    meta: _91saleId_93QJcpPmT8NoMeta || {},
    alias: _91saleId_93QJcpPmT8NoMeta?.alias || [],
    redirect: _91saleId_93QJcpPmT8NoMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo-teste/[saleId].vue").then(m => m.default || m)
  },
  {
    name: _91saleId_93Lma1cBk49TMeta?.name ?? "vendas-recibo-saleId",
    path: _91saleId_93Lma1cBk49TMeta?.path ?? "/vendas/recibo/:saleId()",
    meta: _91saleId_93Lma1cBk49TMeta || {},
    alias: _91saleId_93Lma1cBk49TMeta?.alias || [],
    redirect: _91saleId_93Lma1cBk49TMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendas/recibo/[saleId].vue").then(m => m.default || m)
  },
  {
    name: indexdSYSE88XfkMeta?.name ?? "vendedores",
    path: indexdSYSE88XfkMeta?.path ?? "/vendedores",
    meta: indexdSYSE88XfkMeta || {},
    alias: indexdSYSE88XfkMeta?.alias || [],
    redirect: indexdSYSE88XfkMeta?.redirect,
    component: () => import("/home/runner/work/apex-pos/apex-pos/frontend/pages/vendedores/index.vue").then(m => m.default || m)
  }
]