import { defineStore } from "pinia";

export const useOnboardingStore = defineStore("onboardingStore", {

  state: () => ({

    onboardingComplete : false,
    
    steps: [],

  }),


  actions: {

    stepIncomplete(stepName) {
      let step = this.steps.find(step => step.step === stepName);

      return (step) ? !step.is_completed : false;
    },

    setOnboardingData(steps, onboardingComplete) {
      
      if (onboardingComplete) {
        this.onboardingComplete = true;
      }

      this.steps = steps;
    },

    completeStep(stepName) {
      let step = this.steps.find(step => step.step === stepName);
      
      if (step) {
        step.is_completed = true;
      } 
    }
  }
})  