import Broadcast from '../utils/broadcast';

export default defineNuxtPlugin((NuxtApp) => {
	return {
		provide: {
			broadcast: new Broadcast({
				broadcaster: 'pusher',
				key: null,
				cluster: 'sa1',
				encrypted: true,
				forceTLS: true,
				authorizer: (channel, option) => {
					return {
						authorize: (socketId, callback) => {
							http.httpClient.post('/broadcasting/auth', {
								socket_id: socketId,
								channel_name: channel.name
							})
								.then(response => {
									callback(false, response.data);
								})
								.catch(error => {
									callback(true, error);
								});
						}
					};
				}
			}, NuxtApp.$http)
		},
	};
});
