import { useAppStore } from "~/store/app/app";


export default defineNuxtPlugin(() => {
  const appStore = useAppStore()

  return {
    provide: {
      snackbar: function (props) {
        appStore.setSnackbar(props)
      },
    },
  };
});
