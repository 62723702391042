import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { md3 } from 'vuetify/blueprints'
import { aliases, fa } from 'vuetify/iconsets/fa'
import { mdi } from 'vuetify/iconsets/mdi'

import '@fortawesome/fontawesome-free/css/all.css'
import '@mdi/font/css/materialdesignicons.css'

import { VTextField } from 'vuetify/components'
import { VTextarea } from 'vuetify/components'
import { VSelect } from 'vuetify/components'
import { VBtn } from 'vuetify/components'
import { VCard } from 'vuetify/components'
import { VCheckbox } from 'vuetify/components'
import { VSwitch } from 'vuetify/components'
import { pt } from 'vuetify/locale'

// labs
 

export default defineNuxtPlugin(nuxtApp => {
  const vuetify = createVuetify({

    locale: {
      locale: 'pt',
      current: 'pt',
      messages: { pt },
    },

    components: {
      ...components,
      
    },
    directives,

    // blueprint: md3,

    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
        mdi,
      }
    },

    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            background: '#ffffff',
            surface: '#f6f6f6',
            drawer: '#f6f6f6',
            primary: '#02B25F', // #E53935
            secondary: '#2c3d8f', // #FFCDD2
            success: '#02B25F', // #FFCDD2
            info: '#00ACC1',
            error: '#F44336',
          }
        },
        dark: {
          dark: true,
          colors: {
            background: '#111827',
            surface: '#1f2937',
            drawer: '#1f2937',
            primary: '#02B25F',
            secondary: '#2c3d8f', // #FFCDD2
            success: '#02B25F', // #FFCDD2
            info: '#0057d9',
            error: '#F44336',

            'on-surface-variant': "#313a47"
          }
        }
      },
    },

    aliases: {
      AppBtn: VBtn,
      AppTextField: VTextField,
      AppDateField: VTextField,
      AppTextarea: VTextarea,
      AppSelect: VSelect,
      AppCard: VCard,
      AppCheckbox: VCheckbox,
      AppSwitch: VSwitch,
    },

    defaults: {

      AppBtn: { rounded: "lg", elevation: 0 },

      AppTextField: { variant: 'solo-filled', flat: true, rounded: "lg", hideDetails: 'auto' },

      AppDateField: { variant: 'solo-filled', flat: true, rounded: "lg", hideDetails: 'auto', type: 'date' },

      AppTextarea: { variant: 'solo-filled', flat: true, rounded: "lg", hideDetails: 'auto' },

      AppSelect: { variant: 'solo-filled', flat: true, rounded: "lg", hideDetails: true, ['menu-icon']: "mdi:mdi-chevron-down" },

      AppCard: { elevation: 0, rounded: "lg" },

      AppSwitch: { color: 'primary', hideDetails: 'auto' },

      AppCheckbox: { color: 'primary', hideDetails: 'auto' },

      VTable: { rounded: "lg" },

      VDataTable: { itemsPerPage: -1, rounded: "lg" },
    },
  })

  nuxtApp.vueApp.use(vuetify)
})