import { useAuthStore } from '../store/app/auth';

export default defineNuxtPlugin(() => {

  const baseURL = (process.env.NODE_ENV === 'production') ? 'https://assistant-api.apexcomercio.app' : 'http://localhost:3000';

  const fetchInstance = async (url, options = {}) => {
    
    const authStore = useAuthStore(); 

    const token = authStore.authToken; 

    const companyId = authStore.selectedCompany?.id;

    const headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;  
    }

    if (companyId) {
      headers['X-Company-Id'] = companyId;
    }

    try {
      const response = await fetch(`${baseURL}${url}`, {
        ...options,
        headers,
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      return await response;
    } catch (error) {
      console.error('Fetch error:', error);
      throw error;
    }
  };

  const assistantApi = {
    
    get: (url, options = {}) => fetchInstance(url, { method: 'GET', ...options }),

    post: (url, data, options = {}) => fetchInstance(url, {
      method: 'POST',
      body: JSON.stringify(data),
      ...options,
    }),

    put: (url, data, options = {}) => fetchInstance(url, {
      method: 'PUT',
      body: JSON.stringify(data),
      ...options,
    }),

    delete: (url, options = {}) => fetchInstance(url, { method: 'DELETE', ...options }),
  };

  return {
    provide: {
      assistantApi,
    },
  };
});
