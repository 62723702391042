
// import { initializeApp } from 'firebase/app'
// import { getMessaging, onMessage } from "firebase/messaging";


export default defineNuxtPlugin(nuxtApp => {

  // const firebaseConfig = {
  //   apiKey: "AIzaSyDOy8Z7_jqlkl-55_uC1G4o6P5m6yapQnY",
  //   authDomain: "mestre-ebd-ed241.firebaseapp.com",
  //   projectId: "mestre-ebd-ed241",
  //   storageBucket: "mestre-ebd-ed241.appspot.com",
  //   messagingSenderId: "5396831847",
  //   appId: "1:5396831847:web:ada506997c05c51c3ebec2",
  //   measurementId: "G-89B7M4CEWQ"
  // };

  // const app = initializeApp(firebaseConfig)
  // const messaging = getMessaging(app);

  // nuxtApp.vueApp.provide('messaging', messaging)
  // nuxtApp.provide('messaging', messaging)

  // onMessage(messaging, (payload) => {
  //   alert('Message received. ', payload);
  //   console.log('Message received. ', payload);
  // });
})