import Http from "./../api/Http.js";

export default defineNuxtPlugin(() => {
	return {
		provide: {
			http: new Http({
				baseURL: process.env.NODE_ENV === "production" ? "https://api.apexcomercio.app/api" : "http://localhost:8000/api",
			}),
		},
	};
});
