<template>
  <v-snackbar id="app-snackbar" v-bind="snackbar" v-model="isVisible">
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="isVisible = false">
        <AppIcon name="hugeicons:cancel-01" size="16" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
const app = useAppStore();

const isVisible = ref(false);

const snackbar = computed(() => app.snackbar);

watch(
  () => app.snackbar,
  (value) => {
    if (value) {
      isVisible.value = true;
    }
  }
);
</script>

<style lang="scss">
#app-snackbar {
  width: 100%;
  padding: 10px 20px;
}
</style>
