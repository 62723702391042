<template>
  <Icon v-bind="$attrs" :style="{ color: color }" size="22px" />
</template>

<script setup>
import { useTheme } from "vuetify";

const $attrs = useAttrs();

const theme = useTheme();

const color = computed(() => {
  return theme.current.value.colors[$attrs.color] || $attrs.color;
});
</script>

<style lang="scss" scoped></style>
