
import calc from '../utils/calc'

export default defineNuxtPlugin(() => {
  return {
    provide: {
      calc: calc
    },
  };
});
