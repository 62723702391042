import { defineStore } from "pinia";

export const useAppStore = defineStore("appStore", {

  state: () => ({

    httpError: null,

    isLoading: false,

    themeDefault: 'light',

    printType: localStorage.getItem("type-print") || 'thermal',

    thermalSize: localStorage.getItem("thermal-size") || '80mm',

    snackbar: null,

    initialAnimation: false

  }),

  getters: {
    theme: (state) => {
      return localStorage.getItem('app-theme') || state.themeDefault
    },
    pwaInstalled() {
      return localStorage.getItem('pwa-installed') || false
    },
    thermalSizeParams: (state) => {
      return state.thermalSize === '80mm' ? { 'max-width': '78mm', 'font-size': '10px' } : { 'max-width': '54mm', 'font-size': '9px' }

    }

  },

  actions: {

    setHttpError(error) {
      this.httpError = error
    },

    setLoading(loading) {
      this.isLoading = loading
    },

    startLoading() {
      this.isLoading = true
    },

    endLoading() {
      this.isLoading = false
    },
    setTheme(theme) {
      localStorage.setItem('app-theme', theme)
    },
    setPrintType(value) {
      localStorage.setItem("type-print", value);
    },
    setThermalSize(value) {
      localStorage.setItem("thermal-size", value);
    },
    setPwaInstalled(value) {
      localStorage.setItem('pwa-installed', value)
    },
    setSnackbar(props) {
      this.snackbar = props
    }

  }
})