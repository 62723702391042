
import { differenceInDays, parseISO } from 'date-fns'

export default {

  round(value, decimals = 2) {
    return Number(Math.round(parseFloat(value) + 'e' + decimals) + 'e-' + decimals);
  },

  sum(arr, field) {
    return arr.reduce((sum, row) => sum + parseFloat(row[field]), 0);
  },

  divideParcelsValue(value, divisor) {
    
    let parcels = this.round(value / divisor)

    let remainder = value - (parcels * divisor)

    return {
      firstValue : this.round(parcels + remainder),
      value : parcels
    }
  },

  makeParcelsArray(value, numParcels, daysInterval) {
    
    let baseValue = Math.floor(value / numParcels * 100) / 100;
    
    let remainder = value - baseValue * numParcels;
    
    let parcels = new Array(numParcels).fill(baseValue);
    
    parcels[0] += remainder;
    
    return parcels;
  },

  ratio(values, valueToRatio, precision = 2) {
    
    var sum = values.reduce((acc, val) => { 

      return acc + parseFloat(val) }, 0)
    
    var newArr = values.map(row => this.round(row * parseFloat(valueToRatio) / sum, precision))

    return newArr
  },

  interest(value, interestRate, due_date, grace_period = 0, calc_method = 'simple') {

    let days = this.getDueDateDays(due_date, grace_period)
    
    if (days <= 0) {
      return 0
    }

    if (calc_method == 'simple') {
      return this.calcSimpleInterest(value, interestRate, days)
    } else {
      return this.calcCompoundInterest(value, interestRate, days)
    }
  },

  calcSimpleInterest(value, interestRate, days) {

    let dayInterestRate = (interestRate / 100) / 30

    return value * dayInterestRate * days 
  },

  calcCompoundInterest(value, interestRate, days) {
    
    let dayInterestRate = (1 + interestRate / 100) ** (1 / 30)

    return value * ((dayInterestRate ** days) - 1)
  },

  getDueDateDays(due_date, grace_period = 0) {
    
    let days = differenceInDays(new Date(), parseISO(due_date))

    if (days > grace_period) {
      return days
    } else {
      return 0
    }
  },

  interestRatePerMonthToPerDay(interestPerMonth) {
    return  (((1 + (interestPerMonth / 100)) ** (1 / 30)) - 1 ) * 100
  }

};