<template>
  <v-snackbar v-model="snackbar" multi-line color="error" :timeout="3000">
    {{ errorMessage }}
    <template v-slot:actions>
      <v-btn variant="text" @click="snackbar = false">
        <v-icon icon="mdi:mdi-close" />
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>

const appStore = useAppStore()

const error = computed(() => appStore.httpError)

const snackbar = ref(false)

const errorMessage = ref('Ops, tivemos um erro...')

watch(error, (error) => {
 
  handleError(error);

})


const showErrorMessage = (message) => {
  errorMessage.value = message
  snackbar.value = true
}

const handleError = (error) => {
  
  if (isHttpError(error)) {
    
    const httpErrorCode = getHttpErrorCode(error)

    if (shouldShowMessage(httpErrorCode)) {
      
      let message = getHttpErrorMessage(httpErrorCode, error)
      
      showErrorMessage(message)
      
      return 
    }
  }

  if (isNetworkError(error)) {
    
    showErrorMessage('Ocorreu um erro no servidor, mas nossa equipe já está sendo alertada.')
    
    return 
  }

  showFallbackErrorMessage()
}


const isHttpError = (error) => {
  return error.code === 'ERR_BAD_REQUEST' || error.code === 'ERR_BAD_RESPONSE'
}

const isNetworkError = (error) => {
  return error.code === 'ERR_NETWORK'
}

const shouldShowMessage = (httpErrorCode) => {
  if (httpErrorCode === 404 || httpErrorCode === 422 || httpErrorCode === 500) {
    return true
  }
}

const getHttpErrorCode = (error) => {
  return error.response.status
}

const getHttpErrorMessage = (httpErrorCode, error) => {
  if (httpErrorCode === 422) {
    return get422ErrorMessage(error)
  }

  if (httpErrorCode === 404) {
    return 'Algo deu errado! Recurso não encontrado.'
  }

  if (httpErrorCode === 500) {
    return 'Ocorreu um erro no servidor, mas nossa equipe já está sendo alertada.'
  }

  return 'Erro ao enviar a requisição.'

}

const get422ErrorMessage = (error) => {
  console.log('erro 22')
  let errorMsg = error.response.data;

  if (typeof errorMsg == "object") {
    if (Object.keys(errorMsg).length == 0) {
      return "Erro no formulário, verifique se todos os campos estão preenchidos corretamente.";
    }

    if (errorMsg.errors) {
      return errorMsg.errors[Object.keys(errorMsg.errors)[0]][0];
    }
  }

  if (error.response.data) {
    return error.response.data;
  }
}

const showFallbackErrorMessage = () => {
  showErrorMessage('Algo deu errado!')
}


// export default {
//   data: function () {
//     return {
//       err: null,

//       alertData: {
//         message: null,
//         isVisible: false,
//         type: "danger",
//       },
//     };
//   },

//   computed: {
//     error() {
//       return this.$store.state.app.httpError;
//     },
//   },

//   mounted() {
//     this.err = this.error;

//     this.processError();
//   },

//   watch: {
//     error: function (newError) {
//       this.err = newError;

//       this.processError();
//     },
//   },

//   methods: {
//     onSnackbarInput() {
//       this.$store.commit("app/resetHttpError");
//     },

//     setError(error) {
//       this.err = error;

//       this.processError();
//     },

//     processError() {
//       if (!this.err) {
//         this.alertData.isVisible = false;

//         return;
//       }

//       console.log("houston we have a problem...");

//       console.log(this.err);

//       if (this.gotError("300")) {
//         this.process300();

//         return;
//       }

//       if (this.gotError("401")) {
//         this.showAlert('Usuário ou senha inválidos')

//         return;
//       }

//       if (this.gotError("403")) {
//         this.showAlert("Página bloqueada.");

//         return;
//       }

//       if (this.gotError("419")) {
//         this.processExpiredSession();

//         return;
//       }

//       if (this.gotError("422")) {
//         console.log("sound's like a 422 error...lets try understand");

//         this.process422();

//         return;
//       }

//       if (this.gotError("500")) {
//         console.log("wtf, its a 500 error...");

//         this.showAlert(
//           "Ocorreu um erro no servidor, mas nossa equipe já está sendo alertada."
//         );

//         return;
//       }

//       if (this.gotNetworkError()) {
//         this.showAlert(
//           "Erro na conexão, cheque sua conexão ou tente mais tarde."
//         );

//         return;
//       }

//       this.showAlert("Algo deu errado!");
//     },

//     gotError(errorCode) {
//       if (this.err.response) {
//         if (this.err.response.status) {
//           return this.err.response.status == errorCode;
//         }
//       }

//       return false;
//     },

//     processExpiredSession() {
//       // todo change to $auth.sessionExpired()
//       window.location.href = "/login";
//     },

//     process300() {
//       console.log(this.err.response.data)
//       // check is has redirect 

//       // 
//       //this.showAlert(this.extractErrorMessage());
//     },

//     process422() {
//       this.showAlert(this.extractErrorMessage());
//     },

//     gotNetworkError() {
//       return this.err.message == "Network Error";
//     },

//     extractErrorMessage() {
//       let errorMsg = this.err.response.data;

//       if (typeof errorMsg == "object") {
//         if (Object.keys(errorMsg).length == 0) {
//           return "Algo deu errado!";
//         }

//         if (errorMsg.errors) {
//           return errorMsg.errors[Object.keys(errorMsg.errors)[0]][0];
//         }

//       }

//       if (this.err.response.data) {
//         return this.err.response.data;
//       }

//       return "Algo deu errado!";
//     },

//     showAlert(msg) {
//       this.alertData.message = msg;

//       this.alertData.isVisible = true;
//     },
//   },
// };
</script>

