import { defineStore } from "pinia";

export const useSettingsStore = defineStore("settingsStore", {

  state: () => ({

    http: useNuxtApp().$http,

    authStore: useAuthStore(),

    settings: {},

    userSettings: {},

  }),

  getters: {
    setting: (state) => (key) => {
      return state.settings[key]
    },

    userSetting: (state) => (key) => {
      return state.userSettings[key]
    }
  },

  actions: {

    // settings is a object
    setSettings(settings) {

      // loop through object
      for (const [key, value] of Object.entries(settings)) {
        this.settings[key] = value
      }

    },

    setUserSettings(settings) {

      // loop through object
      for (const [key, value] of Object.entries(settings)) {
        this.userSettings[key] = value
      }

    },

    setSetting(key, value) {
      this.settings[key] = value
    },

    setUserSetting(key, value) {
      this.userSettings[key] = value
    },

    getSetting(key) {
      return this.settings[key]
    },

    getUserSetting(key) {
      return this.userSettings[key]
    },

    async storeSettings(form) {

      let response = await this.http.store('company/settings', form)

      this.setSettings(form)
    },

    async storeUserSettings(form) {

      let response = await this.http.store('user/settings', form)

      this.setUserSettings(form)
    }

  }
})




