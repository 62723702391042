import Echo from 'laravel-echo';
import Pusher from 'pusher-js';


class Broadcast {

  constructor(options, http) {

    this.http = http

    if (options.key) {

      this.echo = new Echo({
        ...options,
        client: new Pusher(options.key, options)
      });
    }
  }

  private(channel, event, callback) {
    this.echo.private(channel).listen(event, callback)
  }

  channel(channel, event, callback) {
    this.echo.channel(channel).listen(event, callback)
  }

}

export default Broadcast
