<template>
  <v-layout class="rounded rounded-md">
    <v-main id="main" class="d-flex align-center justify-center">
      <div>

        <div class="text-center mb-6">

          <v-avatar class="blob" size="100px" color="#F5E0DE">
            <AppIcon color="error" name="hugeicons:alert-02" size="65" />
          </v-avatar>

          <h2 class="mt-2">
            Ops! Algo deu errado.
          </h2>
        </div>
        <div class="text-center px-8">
          {{ error }}
        </div>
        <div class="d-flex justify-center mt-6 ">
          <div class="d-flex flex-column" style="width: 100%;">

            <AppBtn @click="handleError()" color="primary" class="mb-2">
              <AppIcon class="mr-2" name="hugeicons:arrow-left-01" size="20" />
              Voltar ao sistema
            </AppBtn>

            <AppBtn @click="handleRefresh()" color="primary" variant="outlined" class="mb-2">
              <AppIcon class="mr-2" name="hugeicons:refresh" size="20" />
              Atualizar
            </AppBtn>

            <AppBtn @click="handleHelp()" color="primary" variant="outlined">
              <AppIcon class="mr-2" name="hugeicons:customer-support" size="20" />
              Suporte
            </AppBtn>
          </div>
        </div>
      </div>
    </v-main>
  </v-layout>
</template>

<script setup>

const props = defineProps({
  error: {}
})
const handleHelp = () => {
  window.open(
    "https://wa.me/553391147649?text=Olá, preciso de ajuda no Apex Comércio."
  );
};
const handleError = () => clearError({ redirect: '/' })

const handleRefresh = () => {
  window.location.reload();
  handleError()
  window.location.href = '/'
}

</script>

<style lang="scss">
#main {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .v-avatar {
    border: none;
  }

  .blob {
    background: red;
    border-radius: 50%;
    margin: 10px;



    box-shadow: 0 0 0 0 rgba(255, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
  }

  span {
    text-transform: none;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
  }
}
</style>