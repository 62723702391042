<template>
  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular color="primary" indeterminate size="64" />
  </v-overlay>
</template>

<script setup>
const appStore = useAppStore();

const isLoading = computed(() => appStore.isLoading);
</script>

