import validate from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/home/runner/work/apex-pos/apex-pos/frontend/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  acl: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Acl.js"),
  admin: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Admin.js"),
  auth: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Auth.js"),
  "company-auth": () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/CompanyAuth.js"),
  feature: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Feature.js"),
  guest: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Guest.js"),
  subscription: () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/Subscription.js"),
  "system-admin": () => import("/home/runner/work/apex-pos/apex-pos/frontend/middleware/SystemAdmin.js")
}