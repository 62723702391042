import { defineNuxtPlugin } from '#app'
import posthog from 'posthog-js'
export default defineNuxtPlugin(nuxtApp => {
  
  const runtimeConfig = useRuntimeConfig();
  
  if (import.meta.env.MODE !== 'development') {

    var posthogClient = posthog.init(runtimeConfig.public.posthogPublicKey, {
      api_host: runtimeConfig.public.posthogHost,
      person_profiles: 'identified_only', // or 'always' to create profiles for anonymous users as well
      capture_pageview: false, // we add manual pageview capturing below
      autocapture: false,
      disable_session_recording: true,
      loaded: (posthog) => {
        if (import.meta.env.MODE === 'development') posthog.debug();
      }
    })

    
    // Make sure that pageviews are captured with each route change
    const router = useRouter();
    
    router.afterEach((to) => {
      nextTick(() => {
        posthog.capture('$pageview', {
          current_url: to.fullPath
        });
      });
    });

  }

  return {
    provide: {
      posthog: () => posthogClient
    }
  }
})